import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import React from "react";
import {targetLeadTime} from "./utils";
import GraphTooltip from "./graph-helpers/GraphTooltip";
import GraphBase from "./graph-helpers/GraphBase";

const GRAPH_COLOR = '#F0A202';
const LeadTimeGraph = ({stat, classes, ignoreLeadTime = false, updateTime}) => {
    const data = [{
        id: "leadTime",
        data: stat.lead_time.map((st, index) => {
            const timestamp = new Date(updateTime);
            timestamp.setMinutes(updateTime.getMinutes() - index);

            return {
                x: timestamp.toISOString(),
                y: st
            }
        }).reverse()
    }];
    const chipColor = ignoreLeadTime ? 'default' : stat.lead_time[0] > targetLeadTime(stat) ? "secondary" : "default";
    return (
        <Grid item xs={4} md={3} className={classes.numColumn}>
            <Paper elevation={1} className={classes.paper} square={true}>
                <Chip size="small"
                      className={classes.chip}
                      label={Math.round(stat.lead_time[0]).toLocaleString()}
                      color={chipColor}
                />
                <GraphBase
                    data={data}
                    graphColor={GRAPH_COLOR}
                    tooltip={({point}) => {
                        return (
                            <GraphTooltip content={`Averaging ${Number(point.data.yFormatted).toFixed(2)} minutes until item is reviewed at ${point.data.xFormatted}`}></GraphTooltip>
                        )
                    }}
                    defs={[
                        { id: 'squares', type: 'patternSquares', padding: 2, size: 4, stagger: false, color: '#7A5000', background: GRAPH_COLOR },
                    ]}
                    fill={[
                        { match: '*', id: 'squares' },
                    ]}
                >
                </GraphBase>
            </Paper>
        </Grid>
    )
}

export default LeadTimeGraph;
