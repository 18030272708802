import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import React from "react";
import GraphTooltip from "./graph-helpers/GraphTooltip";
import GraphBase from "./graph-helpers/GraphBase";

const GRAPH_COLOR = '#3B8EA5';
const ThroughputGraph = ({stat, classes, updateTime}) => {
    const data = [{
        id: "throughput",
        data: stat.throughput.map((st, index) => {
            const timestamp = new Date(updateTime);
            timestamp.setMinutes(updateTime.getMinutes() - index);

            return {
                x: timestamp.toISOString(),
                y: st
            }
        }).reverse()
    }];
    return (
        <Grid item xs={4} md={3} className={classes.numColumn}>
            <Paper elevation={1} className={classes.paper} square={true}>
                <Chip className={classes.chip} size="small"
                      label={Math.round(stat.throughput[0] * 60).toLocaleString()}/>
                <GraphBase
                    data={data}
                    graphColor={GRAPH_COLOR}
                    tooltip={({point}) => {
                        return (
                            <GraphTooltip content={`Processing ${Number(point.data.yFormatted).toFixed(2)} items per minute at ${point.data.xFormatted}`}></GraphTooltip>
                        )
                    }}
                    defs={[
                        { id: 'lines', type: 'patternLines', padding: 1, rotation: 45, spacing: 6, lineWidth: 3, color: '#2B6778', background: GRAPH_COLOR },
                    ]}
                    fill={[
                        { match: '*', id: 'lines' },
                    ]}
                >
                </GraphBase>
            </Paper>
        </Grid>
    )
}

export default ThroughputGraph;
