import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import React from "react";

import GraphTooltip from "./graph-helpers/GraphTooltip";
import GraphBase from "./graph-helpers/GraphBase";

const GRAPH_COLOR = '#527E3A';
const NumberOfItemsGraph = ({stat, classes, updateTime}) => {
    const data = [{
        id: "numberOfItems",
        data: stat.wip.map((st, index) => {
            const timestamp = new Date(updateTime);
            timestamp.setMinutes(updateTime.getMinutes() - index);

            return {
                x: timestamp.toISOString(),
                y: st
            }
        }).reverse()
    }];

    return (
        <Grid item xs={4} md={3} className={classes.numColumn}>
            <Paper elevation={1} className={classes.paper} square={true}>
                <Chip className={classes.chip} size="small"
                      label={stat.wip[0].toLocaleString()}/>

                <GraphBase
                    data={data}
                    graphColor={GRAPH_COLOR}
                    tooltip={({point}) => {
                        return (
                            <GraphTooltip content={`${point.data.yFormatted} items in queue at ${point.data.xFormatted}`}></GraphTooltip>
                        )
                    }}
                    defs={[
                        { id: 'dots', type: 'patternDots', padding: 2, size: 3, stagger: true, color: '#2E4620', background: GRAPH_COLOR },
                    ]}
                    fill={[
                        { match: '*', id: 'dots' },
                    ]}
                >
                </GraphBase>
            </Paper>
        </Grid>
    )
}

export default NumberOfItemsGraph;
