import React from "react";
import {ResponsiveLine} from "@nivo/line";

const GraphBase = ({data, graphColor, pattern, ...overrides}) => {
    return (
        <div style={{height: '100%', width: '100%'}}>
            <ResponsiveLine
                data={data}
                curve={"monotoneX"}
                lineWidth={4}
                margin={{top: 40, bottom: 5, left: 5, right: 5}}
                xScale={{
                    type: "time",
                    format: '%Y-%m-%dT%H:%M:%S.%LZ',
                    precision: 'minute',
                }}
                xFormat={"time:%I:%M %p"}
                yScale={{
                    type: 'linear',
                    min: "auto",
                    max: "auto"
                }}
                pointSize={4}
                enableGridX={false}
                enableGridY={false}
                useMesh={true}
                axisBottom={{
                    tickValues: "every 5 minutes",
                    tickSize: 4,
                    tickPadding: 5,
                    tickRotation: 0,
                    format: "%I:%M %p",
                }}
                theme={{
                    domain: {
                        line: {
                            stroke: graphColor,
                            strokeWidth: 4
                        }
                    },
                    axis: {
                        domain: {
                            line: {
                                stroke: '#ffffff'
                            }
                        },
                        ticks: {
                            line: {
                                stroke: '#ffffff'
                            }
                        }
                    },
                    crosshair: {
                        line: {
                            stroke: '#ffffff'
                        }
                    }
                }}
                enableArea={true}
                areaOpacity={0.3}
                animate={false}
                pointColor={graphColor}
                colors={[graphColor]}
                {...overrides}
            ></ResponsiveLine>
        </div>
    )
}
export default GraphBase;
