import React, {useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from '@mui/material/styles'
import {calcLeadTime, calcWorkersToAdd, getRelevantStats, L2_TARGET_LEAD_TIME, queueType, statsSmooth} from "./utils";
import NumberOfItemsGraph from "./NumberOfItemsGraph";
import ThroughputGraph from "./ThroughputGraph";
import LeadTimeGraph from "./LeadTimeGraph";

const prefix = 'ItemGraph';
const classes = {
    root: `${prefix}-root`,
    wrapper: `${prefix}-wrapper`,
    textColumn: `${prefix}-textColumn`,
    numColumn: `${prefix}-numColumn`,
    paper: `${prefix}-paper`,
    error: `${prefix}-error`,
    warning: `${prefix}-warning`,
    icon: `${prefix}-icon`,
    chip: `${prefix}-chip`,
    messageColumn: `${prefix}-messageColumn`,
    notFound: `${prefix}-notFound`,
    gridRow: `${prefix}-gridRow`
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.root}`]: {
        display: 'flex',
        '& > *': {
            alignItems: 'center',
            margin: 'auto',
            height: '50%',
        },
    },
    [`& .${classes.wrapper}`]: {
        background: '#353a44',
        color: 'white',
        padding: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
                width: '95vw',
            },
            [theme.breakpoints.up('md')]: {
                width: '85vw',
            },
    },
    [`& .${classes.textColumn}`]: {
        textAlign: 'left',
        fontSize: '20pt',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '4pt',
        },
    },
    [`& .${classes.numColumn}`]: {
        textAlign: 'right',
    },
    [`& .${classes.paper}`]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        background: "#1B1D23",
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        height: '15vh',
        width: '20vw',
        position: 'relative',
            [theme.breakpoints.down('sm')]: {
                width: '30vw',
            },
    },
    [`& .${classes.error}`]: {
        display: 'flex',
        color: theme.palette.error.main,
        fontSize: '12pt',
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    [`& .${classes.warning}`]: {
        display: 'flex',
        color: theme.palette.warning.main,
        fontSize: '12pt',
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    [`& .${classes.icon}`]: {
        marginRight: '4pt'
    },
    [`& .${classes.chip}`]: {
        position: 'absolute',
        right: '0',
        width: 'fit-content',
        fontSize: '14pt',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginLeft: 'auto'
    },
    [`& .${classes.messageColumn}`]: {
        textAlign: 'center',
        marginBottom: '4pt',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    [`& .${classes.notFound}`]: {
        fontSize: '14px'
    },
    [`& .${classes.gridRow}`]: {
        minHeight: '70px',
        color: 'white',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderBottomColor: 'rgba(255, 255, 255, 0.12)',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        alignItems: 'center'
    }
}));

function Stats() {
    const [stats, setStats] = useState([]);
    const [loadTime, setLoadTime] = useState(new Date());
    const [updateTime, setUpdateTime] = useState(null);
    const [includeAudit, setIncludeAudit] = useState(false);
    const [imageOnly, setImageOnly] = useState(false);

    useEffect(() => {
        fetch('/stats.json')
            .then(response => {
                setUpdateTime(new Date(Date.parse(response.headers.get('Last-Modified'))));
                return response.json();
            })
            .then(stats =>
                stats.map(statsSmooth(5))
                    .map(calcLeadTime)
                    .map(calcWorkersToAdd)
            )
            .then(setStats)
            .catch(function (error) {
                console.log(error)
            })
    }, [setStats, setUpdateTime, loadTime])

    useEffect(() => {
        const intervalId = setInterval(() => {
            setLoadTime(new Date())
        }, 10000)

        return () => clearInterval(intervalId);
    }, [setLoadTime]);

    const handleIncludeAuditChange = (event) => {
        setIncludeAudit(event.target.checked);
    };
    const handleImageOnlyChange = (event) => {
        setImageOnly(event.target.checked);
    };

    return (
        <Root className={classes.root}>
            <Paper className={classes.wrapper} variant={'outlined'}>
                <Grid container>
                    <Grid container>
                        <Grid item md={3}>

                        </Grid>
                        <Grid item xs={4} md={3}>
                            <Typography variant="subtitle1" gutterBottom>
                                # Items
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={3}>
                            <Typography variant="subtitle1" gutterBottom>
                                Throughput <br/>(per hr)
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={3}>
                            <Typography variant="subtitle1" gutterBottom>
                                Lead Time <br/>(mins)
                            </Typography>
                        </Grid>
                    </Grid>
                    {
                        imageOnly &&
                        getRelevantStats(stats.filter(stat => stat.priority.includes("_imgonly")))
                            .map(stat =>
                                <Grid container key="image-grid" className={classes.gridRow} alignItems={'center'}>
                                    <Grid item xs={12} md={3} className={classes.textColumn}>
                                        <div>{queueType(stat, {isImageOnly: true})}</div>
                                    </Grid>
                                    <NumberOfItemsGraph classes={classes} stat={stat} updateTime={updateTime}/>
                                    <ThroughputGraph classes={classes} stat={stat} updateTime={updateTime}/>
                                    <LeadTimeGraph classes={classes} stat={stat} ignoreLeadTime={true} updateTime={updateTime}/>
                                </Grid>
                            )
                    }
                    {
                        includeAudit && (
                            getRelevantStats(stats.filter(stat => stat.priority.includes("_audit"))).length > 0 ?
                                getRelevantStats(stats.filter(stat => stat.priority.includes("_audit")))
                                    .map(stat =>
                                        <Grid container key="audit-grid" className={classes.gridRow} alignItems={'center'}>
                                            <Grid item xs={12} md={3} className={classes.textColumn}>
                                                <div>{queueType(stat, {isSafetyAudit: true})}</div>
                                            </Grid>
                                            <NumberOfItemsGraph classes={classes} stat={stat} updateTime={updateTime}/>
                                            <ThroughputGraph classes={classes} stat={stat} updateTime={updateTime}/>
                                            <LeadTimeGraph classes={classes} stat={stat} updateTime={updateTime}/>
                                        </Grid>
                                    )
                                :
                                <Grid container key="no-audits-grid" className={classes.gridRow}>
                                    <Grid item xs={12} md={3} className={classes.textColumn}>
                                        Safety Audit
                                    </Grid>
                                    <Grid item md={9} xs={12} className={classes.messageColumn}>
                                        <Typography className={classes.notFound}>No items currently in safety
                                            audit</Typography>
                                    </Grid>
                                </Grid>
                        )
                    }
                    {getRelevantStats(stats.filter(stat => !stat.priority.includes("_audit")))
                        .map(stat =>
                            <Grid container key={stat.item_type + '-' + stat.worker_type} className={classes.gridRow}
                                  alignItems={'center'}>
                                <Grid item xs={12} md={3} className={classes.textColumn}>
                                    <div>
                                        {queueType(stat)}
                                    </div>
                                    {stat.hi_max_age && (stat.hi_max_age[0] / 60) > L2_TARGET_LEAD_TIME &&
                                        <div className={classes.warning}>
                                            <WarningIcon fontSize="small" className={classes.icon}/>
                                            <Typography>P1 item
                                                @ {Math.round(stat.hi_max_age[0] / 60)} minutes</Typography>
                                        </div>
                                    }
                                    {stat.pss_max_age && (stat.pss_max_age[0] / 60) > L2_TARGET_LEAD_TIME &&
                                        <div className={classes.error}>
                                            <ErrorIcon fontSize="small" className={classes.icon}/>
                                            <Typography>escalatePSS item
                                                @ {Math.round(stat.pss_max_age[0] / 60)} minutes</Typography>
                                        </div>
                                    }
                                </Grid>

                                <NumberOfItemsGraph classes={classes} stat={stat} updateTime={updateTime}/>
                                <ThroughputGraph classes={classes} stat={stat} updateTime={updateTime}/>
                                <LeadTimeGraph classes={classes} stat={stat} updateTime={updateTime}/>
                            </Grid>
                        )
                    }
                    <Grid item md={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <FormControlLabel
                                control={
                                    <Switch checked={includeAudit} onChange={handleIncludeAuditChange} color="primary"/>
                                }
                                label="Include Safety Audit"/>
                            <FormControlLabel
                                control={
                                    <Switch checked={imageOnly} onChange={handleImageOnlyChange} color="primary"/>
                                }
                                label="Image Only"/>
                        </Box>
                    </Grid>
                </Grid>
                {updateTime !== null &&
                    <Typography variant="caption" display="block" gutterBottom>
                        Last Update: {updateTime.toLocaleString()}
                    </Typography>
                }
            </Paper>
        </Root>
    );
}

export default Stats;
