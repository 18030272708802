import React, {useEffect} from 'react';
import {createTheme, responsiveFontSizes, ThemeProvider} from "@mui/material/styles";
import './App.css';
import Stats from "./Stats";

let theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#3B8EA5',
        },
        secondary: {
            main: '#DF2935',
        },
        info: {
            main: '#4fc3f7'
        }
    }
});
theme = responsiveFontSizes(theme);

function App() {
    useEffect(() => {
        const intervalId = setInterval(() => {
            window.location = "/?ts=" + new Date().getTime()
        }, 30 * 60 * 1000)

        return () => clearInterval(intervalId);
    });
    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <Stats theme={theme}/>
            </div>
        </ThemeProvider>
    );
}

export default App;
