import React from "react";

const GraphTooltip = ({content}) => {
    return (
        <div style={{backgroundColor: "rgba(0, 0, 0, 0.75)", padding: '2px', borderRadius: '4px'}}>
            <p style={{whiteSpace: 'nowrap', fontSize: '14px', color: '#ffffff'}}>{content}</p>
        </div>
    )
}
export default GraphTooltip;
