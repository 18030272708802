

export const L1_TARGET_LEAD_TIME = 9;
export const L2_TARGET_LEAD_TIME = 4.5;

export const queueType = (stat, {isSafetyAudit = false, isImageOnly = false} = {}) => {
    let append = null;
    if (isSafetyAudit) {
        append = 'Safety Audit'
    } else if (isImageOnly) {
        append = 'Image Only'
    }

    let workerType = stat.worker_type
    if (workerType === 'l1_queue') {
        workerType = 'Standard';
    } else if (workerType === 'l2_queue') {
        workerType = 'Escalation';
    }

    return [workerType, append].filter(v => v).join(" - ");
}

export const targetLeadTime = (stat) => {
    let workerType = stat.worker_type
    if (workerType === 'l1_queue') {
        return L1_TARGET_LEAD_TIME;
    } else if (workerType === 'l2_queue') {
        return L2_TARGET_LEAD_TIME;
    }
    return 0;
}

const sumLists = (a, b) => {
    if (a === undefined || a.length === 0) {
        return b;
    }
    if (b === undefined || b.length === 0) {
        return a;
    }
    return a.map((v, idx) => v + b[idx]);

}

const maxLists = (a, b) => {
    if (a === undefined || a.length === 0) {
        return b;
    }
    if (b === undefined || b.length === 0) {
        return a;
    }
    return a.map((v, idx) => Math.max(v, b[idx]));
}

export const calcLeadTime = (stat) => {
    stat.lead_time = [];
    if (stat.wip !== undefined) {
        for (let i = 0; i < stat.wip.length; i++) {
            stat.lead_time[i] = stat.wip[i] / Math.max(1, stat.throughput[i]);
        }
    }
    return stat;
}

export const calcWorkersToAdd = (stat) => {
    stat.workers_to_add = [];
    for (let i = 0; i < stat.workers.length; i++) {
        let workers = stat.workers[i];
        let throughput = stat.throughput[i];
        let targetThroughput = stat.wip[i] / targetLeadTime(stat);
        let targetWorkers = (targetThroughput / Math.max(1, throughput)) * Math.max(1, workers);

        stat.workers_to_add[i] = targetWorkers - workers;
    }
    return stat;
}

const matcherWorkerTypeMatches = () => {
    return (a, b) => a.worker_type === b.worker_type;
}

const matcherItemTypeMatches = () => {
    return (a, b) => a.item_type === b.item_type;
}

const matcherAnd = (matcherA, matcherB) => {
    return (a, b) => matcherA(a, b) && matcherB(a, b);
}

const smooth = (values, periods) => {
    let new_values = [];
    if (values === undefined) {
        return new_values;
    }
    for (let i = 0; i < values.length; i++) {
        const lookback = Math.min(values.length - 1, i + periods);
        if (lookback === i) {
            new_values[i] = values[i];
        } else {
            let sum = 0;
            for (let j = i; j < lookback; j++) {
                sum += values[j];
            }
            new_values[i] = sum / (lookback - i);
        }
    }
    return new_values;
}

export const statsSmooth = (periods) => {
    return stat => {
        let new_stat = {...stat};
        new_stat.throughput = smooth(stat.throughput, periods);
        new_stat.workers = smooth(stat.workers, periods);
        return new_stat;
    }
}

const statsRollup = (matcher) => {
    return (new_stats, stat) => {
        let rollup_stat = new_stats.find(s => matcher(s, stat));
        if (rollup_stat === undefined) {
            rollup_stat = {...stat, name: '*'};
            new_stats.push(rollup_stat);
        } else {
            rollup_stat.wip = sumLists(rollup_stat.wip, stat.wip);
            rollup_stat.max_age = maxLists(rollup_stat.max_age, stat.max_age);
            rollup_stat.throughput = sumLists(rollup_stat.throughput, stat.throughput);
            rollup_stat.arrival = sumLists(rollup_stat.arrival, stat.arrival);
            rollup_stat.workers = sumLists(rollup_stat.workers, stat.workers);
        }
        calcLeadTime(rollup_stat)
        calcWorkersToAdd(rollup_stat)

        rollup_stat.wip = rollup_stat.wip.map(w => isNaN(w) ? 0 : w)
        rollup_stat.lead_time = rollup_stat.lead_time.map(t => isNaN(t) ? 0 : t)
        rollup_stat.throughput = rollup_stat.throughput.map(t => isNaN(t) ? 0 : t)

        if (stat.priority.includes("_pss")) {
            rollup_stat.pss_max_age = maxLists(rollup_stat.pss_max_age, stat.max_age);
        } else if (stat.priority.includes("_hi")) {
            rollup_stat.hi_max_age = maxLists(rollup_stat.hi_max_age, stat.max_age);
        }

        return new_stats;
    };
}

export const getRelevantStats = (stats) => {
    const rollupMatcher = matcherAnd(
        matcherWorkerTypeMatches(),
        matcherItemTypeMatches()
    );

    return stats
        .reduce(statsRollup(rollupMatcher), [])
        .filter(stat => stat.wip[0] > 0)
        .sort((a, b) => a.worker_type.localeCompare(b.worker_type) || a.item_type.localeCompare(b.item_type))
}
